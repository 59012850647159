import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import MediaBlock from 'MediaBlock'
import Hero from 'Hero'

import Banner from './HowToListenViewBanner'

const HowToListenView = ({ title, heroTitle, mediaBlocks, siblings }) => (
  <Fragment>
    <Hero
      title={heroTitle}
    />
    {
      siblings &&
        <Banner
          siblings={siblings}
        />
    }
    <div
      className='o-container--thin'
    >
      {
        mediaBlocks.map((item, key) =>
          <MediaBlock
            {...item}
            type={item.type}
            key={key}
          />
        )
      }
    </div>
  </Fragment>
)

HowToListenView.propTypes = {
  title: PropTypes.string.isRequired,
  heroTitle: PropTypes.string.isRequired,
  mediaBlocks: PropTypes.array.isRequired,
  siblings: PropTypes.array
}

export default HowToListenView
