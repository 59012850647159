import React from 'react'

import Content from 'Content'

const MediaBlockText = ({ content, contentComponent }) => {
  const SectionContent = contentComponent || Content

  return (
    <SectionContent
      content={content}
      className='c-media-block__text'
    />
  )
}

export default MediaBlockText
