import React from 'react'

const MediaBlockCTA = ({ text }) => (
  <span
    className='c-media-block__link o-action-link--black'
  >
    {text}
  </span>
)

export default MediaBlockCTA
