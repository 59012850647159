import React from 'react'

import View from './MediaBlockView'

const MediaBlock = ({ type, ...props }) => {
  return (
    <View
      type={type}
      {...props}
    />
  )
}

export default MediaBlock
