import React, { Fragment } from 'react'

import { HTMLContent } from 'Content'

import MediaBlockAsymmetrical from './MediaBlockAsymmetrical'
import MediaBlockVanilla from './MediaBlockVanilla'

const MediaBlockView = ({ type, ...props }) => (
  <Fragment>
    {/* Conditional Rendering with enums */}
    {{
      asymmetrical: <MediaBlockAsymmetrical type={type} {...props} />,
      vanilla: <MediaBlockVanilla type={type} {...props} />,
      video: <MediaBlockVanilla type={type} {...props} contentComponent={HTMLContent} />
    }[type]}
  </Fragment>
)

export default MediaBlockView
