import React from 'react'
import PropTypes from 'prop-types'

import toMarkdown from 'utilities/toMarkdown'

import Layout from 'Layout'
import SEO from 'SEO'
import View from './HowToListenView'

const HowToListen = ({ mediaBlocks, heroTitle, title, slug, siblings }) => {
  // Convert block.body to markdown
  const markdownedMediaBlocks = mediaBlocks.map((block) => {
    block.body = toMarkdown(block.body)
    return block
  })

  return (
    <Layout>
      <SEO
        title={title}
        slug={slug}
      />
      <View
        title={title}
        heroTitle={heroTitle}
        mediaBlocks={markdownedMediaBlocks}
        siblings={siblings}
      />
    </Layout>
  )
}

HowToListen.propTypes = {
  title: PropTypes.string.isRequired,
  heroTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  mediaBlocks: PropTypes.array.isRequired
}

export default HowToListen
