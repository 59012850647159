import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import sanitizer from 'utilities/sanitizer'

import HowToListen from 'HowToListen'

const HowToListenTemplate = ({ data }) => {
  const { markdownRemark: page } = data
  const {
    frontmatter,
    fields
  } = page

  return (
    <HowToListen
      title={frontmatter.title}
      heroTitle={frontmatter.heroTitle}
      mediaBlocks={frontmatter.mediaBlocks}
      slug={fields.slug}
      siblings={sanitizer(data.siblings.edges)}
    />
  )
}

HowToListenTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default HowToListenTemplate

export const HowToListenQuery = graphql`
  query HowToListen(
    $id: String!
  ) {
    markdownRemark(
      id: {
        eq: $id
      }
    ) {
      id
      fields {
        slug
      }
      frontmatter {
        title
        heroTitle
        mediaBlocks {
          type
          body
          videoUrl
        }
      }
    }
    siblings: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: {
            eq: "templates/HowToListenTemplate"
          }
        }
      }
      sort: {
        fields: [frontmatter___title]
        order: ASC
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
