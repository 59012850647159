import React from 'react'
import { Link } from 'gatsby'

import MediaBlockCTA from './MediaBlockCTA'

const MediaBlockContent = ({ link, className, children }) => (
  link
    ? <Link
      className={`${className} o-action-link__parent`}
      to={link.slug}
    >
      {children}
      <MediaBlockCTA text={link.text} />
    </Link>
    : <div
      className={`${className} o-action-link__parent`}>
      {children}
    </div>
)

export default MediaBlockContent
