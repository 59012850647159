import React from 'react'
import classNames from 'classnames'

import MediaBlockImage from './MediaBlockImage'
import MediaBlockTitle from './MediaBlockTitle'
import MediaBlockText from './MediaBlockText'
import MediaBlockContent from './MediaBlockContent'

const MediaBlockVanilla = ({ ...props }) => {
  const {
    type,
    title,
    body,
    link,
    image,
    shift,
    contentComponent,
    videoUrl,
    index
  } = props

  return (
    <div
      className={classNames('c-media-block', { [`c-media-block--${type}`]: type })}
    >
      <MediaBlockContent
        className='c-media-block__content'
        link={link}
      >
        {
          title &&
          <MediaBlockTitle text={title} />
        }
        {
          body &&
          <MediaBlockText
            content={body}
            contentComponent={contentComponent}
          />
        }
      </MediaBlockContent>
      {
        image &&
        <MediaBlockImage
          className={`c-media-block__figure ${shift ? `c-media-block__figure--shift` : ``}`}
          image={image}
          index={index}
        />
      }
      {
        type === 'video' &&
        <MediaBlockImage
          className={`c-media-block__figure u-margin-bottom-none ${shift ? `c-media-block__figure--shift` : ``}`}
          videoUrl={videoUrl}
          index={index}
        />
      }
    </div>
  )
}

export default MediaBlockVanilla
