import React from 'react'

import MediaBlockImage from './MediaBlockImage'
import MediaBlockTitle from './MediaBlockTitle'
import MediaBlockText from './MediaBlockText'
import MediaBlockContent from './MediaBlockContent'

const MediaBlockAsymmetrical = ({ ...props }) => {
  const {
    title,
    body,
    link,
    image,
    contentComponent
  } = props

  return (
    <div
      className='c-media-block-asymmetrical'
    >
      <MediaBlockContent
        className='c-media-block-asymmetrical__content'
        link={link}
      >
        {
          title &&
            <MediaBlockTitle text={title} />
        }
        {
          body &&
            <MediaBlockText
              content={body}
              contentComponent={contentComponent} />
        }
      </MediaBlockContent>
      {
        image &&
        <MediaBlockImage
          className='c-media-block-asymmetrical__figure u-margin-bottom-none'
          image={image}
          alt={title}
        />
      }
    </div>
  )
}

export default MediaBlockAsymmetrical
