import React from 'react'

const MediaBlockTitle = ({ text }) => (
  <h3
    className='c-media-block__title u-margin-bottom-small'
  >
    {text}
  </h3>
)

export default MediaBlockTitle
