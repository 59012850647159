import React from 'react'
import { Link } from 'gatsby'

const HowToListenViewBanner = ({ siblings }) => (
  <div
    className='c-how-to-listen__banner u-bgcolor-steam'
  >
    <div
      className='o-container'
    >
      <nav>
        <ul
          className='o-list-inline o-list-inline--with-separator u-ta-c u-fs-14'
        >
          {
            siblings &&
            siblings.map(({ title, slug }, key) =>
              <li
                key={key}
              >
                <Link
                  className='o-action-link--black'
                  activeClassName='is-active'
                  to={slug}
                >
                  {title}
                </Link>
              </li>
            )
          }
        </ul>
      </nav>
    </div>
  </div>
)

export default HowToListenViewBanner
