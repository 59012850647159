import React from 'react'
import Image from 'Image'

const MediaBlockImage = ({ image, className, alt, videoUrl, index }) => {
  if (videoUrl) {
    return (
      <figure
        className={`${className} u-img-full`}
      >
        <div className='c-media-block__video-block'>
          <div className='c-media-block__video'>
            <video src={videoUrl} autoPlay loop muted />
          </div>
        </div>
      </figure>
    )
  }

  return (
    <figure
      className={`${className} u-img-full`}
    >
      <Image
        image={image}
        width={882}
        sizes={[
          `${Image.breakpoint('md')} calc(100vw - 54px)`,
          `${Image.breakpoint('container')} calc((100vw - 54px) / 7)`,
          '882px'
        ]}
        alt={alt}
        lazyload
      />
    </figure>
  )
}

export default MediaBlockImage
